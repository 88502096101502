<template>
  <div>
    <Button
      block text="Обновить КТЭ"
      @click="updateKte"
      class="component__margin-bottom" />
    <div v-for="feature in features" v-bind:key="feature.id" ref="featureList">
      <div
        :id="feature.id"
        :class="`tech-req__features_card ${feature.id === activeIndex
        ? 'tech-req__features_card_active' : ''}`"
        @click="showFeature(feature)">
        <div>
          <div class="tech-req__features_title">
            {{feature.name_feature}}
          </div>
          <div class="tech-req__features_text" v-if="feature.size">
            <span
              v-for="(item, index) in Object.keys(feature.size)"
              v-bind:key="`feature_${index}`">
              {{item}}: {{feature.size[item]}}
            </span>
          </div>
          <div class="tech-req__features_text" v-if="feature.mto">
            МТО: {{feature.mto}}
          </div>
          <div class="tech-req__features_text" v-if="feature.faces">
            Faces: {{feature.faces}}
          </div>
          <div v-if="feature.area">
            Площадь: {{feature.area}}
          </div>
        </div>
        <div>
          <img
            class="tech-req__features_img"
            v-if="feature.img"
            :src="feature.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import Button from '@/components/button/Button.vue';

export default {
  name: 'Features',
  data() {
    return {
      features: null,
      flatFeatures: {},
      activeIndex: null,
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
    };
  },
  props: {
    partData: {
      type: Object,
    },
  },
  methods: {
    getFeatures() {
      query('post', 'getFeaturesByPart', {
        time_stamp: this.partData.time_stamp,
        hash: this.partData.hash,
        file_name: this.partData.file_name,
      }, {
        token: localStorage.getItem('token'),
      }).then((result) => {
        this.features = result.features_result;
        for (const [index, feature] of result.features_result.entries()) {
          if (feature.faces) {
            for (const face of feature.faces) {
              this.flatFeatures[`Face${face}`] = index;
            }
          }
        }
      });
    },
    updateKte() {
      query('post', 'updateFeaturesByPart', {
        time_stamp: this.partData.time_stamp,
        hash: this.partData.hash,
        file_name: this.partData.file_name,
      }, {
        token: localStorage.getItem('token'),
      }).then(() => {
        this.getFeatures();
      });
    },
    showFeature(feature) {
      if (this.activeIndex !== feature.id) {
        this.activeIndex = feature.id;
        const result = [];
        for (const item of feature.faces) {
          result.push(`Face${item}`);
        }
        const groups = [{
          surfaces: result,
          color: this.clickColor,
        }];
        // this.$store.state.model.decorateSurfaces(result);
        this.$store.state.modelV2.decorateSurfaces(groups);
      }
      // this.$store.commit('tech/setDefaultGroups', data.roughness.groups);
    },
    surfaceClicked(name) {
      this.activeIndex = this.features[this.flatFeatures[name]].id;
      const el = document.getElementById(this.activeIndex);
      el.scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
      const result = [];
      for (const face of this.features[this.flatFeatures[name]].faces) {
        result.push(`Face${face}`);
      }
      const groups = [{
        surfaces: result,
        color: this.clickColor,
      }];
      this.$store.state.modelV2.decorateSurfaces(groups);
    },
  },
  mounted() {
    this.$store.state.modelV2.changeSelectorType('component');
    this.$store.commit('modelV2/setSurfaceClick', this.surfaceClicked);
    this.getFeatures();
  },
  components: {
    Button,
  },
};
</script>
